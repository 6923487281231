form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;

    @include breakpoint($tablet) {
        width: 40vw;
    }

    input,
    textarea {
        border-width: 1px;
        border-color: $gray-medium;
        border-style: solid;
        border-radius: 0;
        color: $gray;
        margin-top: $spacer * 0.5;
        margin-bottom: $spacer * 1.5;
        padding: $spacer * 0.5;
        width: 100%;
        color: $black;
    }

    label {
        margin-bottom: $spacer * 0.5;
    }

    .message {
        border-width: 1px;
        border-style: solid;
        padding: $spacer * 0.5;
        margin-bottom: $spacer;
        margin-bottom: $spacer;
        
        &.success {
            border-color: green;
            color: green;
            background-color: lighten(green, 60%);
        }
        &.error {
            border-color: red;
            color: red;
            background-color: lighten(red, 60%);
        }
    }

    &.inline {
        display: flex;
        flex-direction: column;
        justify-content: left;
        float: left;
        width: 100%;

        @include breakpoint($tablet) {
            display: inline-flex;
            flex-direction: row;
            margin: 0;
            height: $spacer * 2.5;
            align-items: center;
        }

        input {
            margin-bottom: $spacer;
            height: $spacer * 2.5;

            @include breakpoint($tablet) {
                display: flex;
                max-width: 200px;
                margin: 0;
            }
        }

        label {
            margin-right: $spacer;
            min-width: 100px;
        }

        button {
            height: $spacer * 2.5 !important;
        }
    }
}
