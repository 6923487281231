// table

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid $gray-medium;
    background-color: $white;

    td {
        background-color: $white;
    }

    tr:nth-child(even){

        td {
            background-color: $background !important;
        }
    }

    th {
        background-color: $gray-dark;
        color: $white;
    }

    th, td {
        text-align: left;
        padding: 8px;
    }

}

  
