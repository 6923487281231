a#willItShip {
    scroll-margin-top: 120px;
}
section.willitship {
	position: relative;
	overflow: hidden;
	display: block;
    background-image: url(../images/WIS-BG2.png);
    background-size: 50vw;
    background-repeat: no-repeat;
    background-color: rgba(60, 100, 177, 0.06);
    padding: 0 !important;
    background-position: 0 0 4rem;

    &--mobile {
        padding-bottom: 0 !important;
        background-color: rgba(60, 100, 177, 0.06);

        @include breakpoint($tablet) {
            display: none;
        }

        .section-title {
            text-align: center;
            margin: 0;
        }
    }

    @include breakpoint($phone) {
        // background-position: 0 50% !important;
    }

    @include breakpoint($tablet) {
        // background-position: 0 70% !important;
        padding: 0 0 4rem !important;
    }

    @include breakpoint($desktop) {
        // background-position: 0;
    }
    

    .willitship-q {
	    display: none;
	    margin: 0 auto;
        // padding-top: $spacer;
        // padding-bottom: $spacer;
        max-width: 100vw !important;

        @include breakpoint($desktop) {
            padding: 0;
        }
        
        &.active {
            display: block;
        }

        h2 {
            font-size: calc(1rem + 0.5vw);
            display: none;

            @include breakpoint($tablet) {
                display: block;
            }
        }

        .question {
            font-size: calc(1.48rem + 1vw);
            line-height: 1.2;
            font-weight: 900;
        }

        .answer::before {
            margin-right: 0.5em;
        }

        .right, .wrong {
            display: none;
            &.active {
                display: block;
            }

            span {
                width: calc(1rem + 0.5vw);
                margin-left: calc(-1rem - 0.7vw);
            }
        }

        .right span {
            color: green;
        }

        .wrong span {
            color: $danger;
        }

    }

    .btn {
        cursor: pointer;
        flex: 1 1 auto;
        margin-bottom: $spacer;

        &.disabled {
            opacity: 0.4;
        }
    }

    img.item {
        width: 50vw;

        &.active-item {
            opacity: 0;
            animation: fadeIn .3s ease-in both;
        }
    }

    .flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: normal;
        align-content: normal;

        @include breakpoint($tablet) {
            flex-direction: row;
        }
    }

    .flex-items {

        .row {
            flex-direction: column;
            // height: 160px;
        }

        .explanation {
            margin-right: $spacer;

            @include breakpoint($tablet) {
                max-width: 80%;
            }

        }
    }

    .flex-items:nth-child(1) {
        display: block;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: auto;
        order: 0;
        width: 50vw;
    }

    .flex-items:nth-child(2) {
        display: block;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: center;
        order: 0;
        // height: 220px;
        padding: 0 4%;

        @include breakpoint($tablet) {
            padding: 0;
        }

        @include breakpoint($desktop) {
            padding-right: 4%;
        }
    } 

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translate3d(0, -20%, 0);
        }
        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

}

@media (max-width: 813px) {
    .btn {
        width: 100%;
        margin-bottom: .5rem;
        font-size: 1rem;
    }
    section.willitship {
        background-size: 100%;
        
        img.item {
            width: 100vw;
        }

        .flex-container {
            flex-direction: column;    
        }

        .willitship-q {
            width: auto;
            max-width: 70vw;

            .section-title {
                display: none;

                @include breakpoint($tablet) {
                    display: flex;
                }
            }

            &.willitship-a {
                .section-title {
                    display: flex;
    
                    @include breakpoint($tablet) {
                        display: none;
                    }
                }

                .col-a, .col-b {
                    display: block;
                }
            }

            .flex-items:nth-child(1) {
                // margin: 0 3%;
                // width: 94%;
            }

            .flex-items:nth-child(2) {
                margin: 0 auto $spacer;
                width: 100%;
                text-align: center;
            }
        }
    }
}