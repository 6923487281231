/* Section: FAQ */

section.faq {
    .section-title {
        text-align: center;
    }

    .row {
        flex-direction: column;

        .faq-accordion {
            margin: 0 auto;
            max-width: 800px;

            &--button {
                cursor: pointer;
                width: 100%;
                transition: 0.4s;
                text-align: left;
                text-transform: unset;
                border: none;
                border-radius: 0%;
                padding: $spacer 16% $spacer $spacer;
                box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
                position: relative;
                background: $button-color;
                color: $button-text;
                @extend %lato-regular;
                margin: ($spacer * 0.5) 0;


                @include breakpoint($desktop) {
                    margin: $spacer 0;
                }

                &:after {
                    content: '+';
                    font-weight: 900;
                    position: absolute;
                    right: 4%;
                    top: 50%;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    transform: translate(-50%, -50%);
                }
 
                &:hover {
                    background-color: $background;
                    color: $black;
                }

                &.active {
                    background-color: $secondary-light;

                    &:after {
                        content: '-';
                        font-weight: 900;
                    }
    
                }
            }
        
            &--contents {
                display: none;
                overflow: hidden;

                p {
                    margin: 1rem;
                    font-size: calc(0.8rem + 0.2vw);
                }

            }
        }
    }
}