section.contact {
    .section-title,
    .section-description {
        text-align: center;
        margin: 0 auto ($spacer * 2);

        @include breakpoint($tablet) {
            text-align: left;
        }

    }

    .row {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;

        @include breakpoint($tablet) {
            flex-direction: row;
        }

        .col {
            flex: .5;
            
            &:nth-child(2) {
                background: url('../images/contact.jpg') no-repeat center center;
                background-size: cover;
            } 

            @include breakpoint($tablet) {
                padding: 0 2rem 0 0;
            }
    
            @include breakpoint($desktop-wide) {
                padding: 0 5rem;
            }

        } 

        form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
            // width: 50vw;

            @include breakpoint($tablet) {
                width: 100%;
                justify-content: left;
            }

            input,
            textarea {
                border-width: 1px;
                border-color: $gray-medium;
                border-style: solid;
                border-radius: 0;
                display: block;
                margin-top: $spacer * 0.5;
                margin-bottom: $spacer * 1.5;
                padding: $spacer;
                width: 100%;            
                color: $black;
            }
            
            label {
                margin-bottom: $spacer * 0.5;
            }
            
            .message {
                border-width: 1px;
                border-style: solid;
                padding: $spacer * 0.5;
                margin-bottom: $spacer;
                margin-bottom: $spacer;
                
                &.success {
                    border-color: $primary-dull;
                    color: $primary-dull;
                    background-color: lighten($primary-dull, 60%);
                }
                &.error {
                    border-color: $danger;
                    color: $danger;
                    background-color: lighten($danger, 60%);
                }
            }
        }
    }
}