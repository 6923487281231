/* Section: (global) */

section {
    display: block;
    position: relative;
    @include clearfix;
    @include section-spacer;

    &[id] {
        scroll-margin-top: 100px;
    }
}