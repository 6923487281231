// Section: Testimonials

.testimonials {
    
    .row {
        background: $white;
        box-shadow: 0px 3px 15px rgba(0,0,0,0.1);

        .col {
            padding: $spacer * 2;
            align-items: flex-start;
            align-self: flex-start;

            &:first-child {
                background-color: $primary;
                display: flex;
                align-self: stretch;

                h3 {
                    color: $white;
                    @extend %oswald-regular;
                    text-transform: uppercase;
                    font-size: calc(1.11rem + 0.4vw);
                }
            }

            .testimonial {
                &-icon {
                    color: $secondary;
                    margin-bottom: $spacer;
                    font-size: 3rem;
                    opacity: 0.6;                    
                    z-index: 0;
                }

                &-author {
                    font-size: initial;
                }
            }

            @include breakpoint($tablet) {
                flex: 1 1;
            }
        }
    }
}