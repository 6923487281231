// mixin
$xsmall: 470px;
$phone: 576px;
$tablet: 813px;
$desktop-small: 1100px;
$desktop: 1400px;
$desktop-wide: 1600px;
$desktop-xxl: 1921px;

@mixin background-hidpi($image-name, $image-extension: "png", $top: top, $left: left, $size: cover, $repeat: no-repeat, $color: transparent) {
    background: url("#{$image-name}.#{$image-extension}") $top $left $repeat $color;
    background-size: $size;
    @media (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 1.5dppx) {
        background: url("#{$image-name}@2x.#{$image-extension}") $top $left $size $repeat $color;
        background-size: $size;
    }
}

@mixin clearfix {
    &:after {
      content: "";
      display: table;
      clear: both;
    }
}

@mixin section-spacer {
    padding: ($spacer * 2) 0 !important;

    @include breakpoint($tablet) {
        padding: ($spacer * 3) 0 !important;
    }

    @include breakpoint($desktop) {
        padding: ($spacer * 4) 0 !important;
    }
}

@mixin btn-primary {
    padding: ($spacer * 0.7) ($spacer * 1.4);
    background-color: $primary;
    transition: all 0.4s;
    text-transform: uppercase;
    text-decoration: none;
    color: $white;
    font-family: $font-oswald;
    line-height: 1;
    font-size: calc(0.8rem + 0.2vw);
    border-width: 0 !important;

    &:hover {
        background-color: $primary-dull;
        text-decoration: none;
    }
}