/* Widget: Button */

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

button, 
.btn {
    display: inline-block;
    background-color: $button-color;
    @include btn-primary;
    font-weight: normal;

    &[disabled] {
        opacity: 0.6;
        background-color: lighten($black, 0.4);
        &::after {
            display: inline-block;
            content: '.';
            animation: rotating 2s linear infinite;
            margin-left: $spacer;
        }
    }
}

.btn-next {
    background-color: $primary;
}

.btn-false {
    background-color: $danger;
}

button:not([disabled]):focus, 
input:not([disabled]):focus, 
select:not([disabled]):focus, 
textarea:not([disabled]):focus {
    outline: 0.2rem solid $primary;
    outline-offset: 0;
}