/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400&family=Oswald&display=swap');

$font-lato: 'Lato', sans-serif;
$font-oswald: 'Oswald', sans-serif;

%lato-normal,
%lato-regular {
    font-family: $font-lato;
    font-weight: 400;
}

%lato-bold {
    font-family: $font-lato;
    font-weight: 700;
}

%lato-black {
    font-family: $font-lato;
    font-weight: 900;
}

%oswald-normal,
%oswald-regular {
    font-family: $font-oswald;
    font-weight: 400;
    letter-spacing: $spacer * 0.015;
}

html {
    @extend %lato-regular;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: 1.2;
    @extend %lato-black;
    color: $primary;
}

h1, .h1 {
    font-size: calc(1.88rem + 0.9vw);
    margin-bottom: $spacer;
}

h2, .h2 {
    font-size: calc(1.48rem + 0.9vw);
    margin-bottom: $spacer;
}

h3, .h3 {
    font-size: calc(1.248rem + 0.7vw);
    margin-bottom: $spacer;
}

h4, .h4 {
    font-size: calc(1.21rem + 0.6vw);
    margin-bottom: $spacer;
}

h5, .h5 {
    font-size: calc(1.11rem + 0.4vw);
    margin-bottom: $spacer;
}

h6, .h6 {
    font-size: calc(0.9rem + 0.4vw);
    margin-bottom: $spacer;
}

p {
    margin-bottom: $spacer;
    font-size: calc(1rem + 0.2vw);
    line-height: 1.6;
}

sub {
    font-size: 75%;
}

a {
    color: $primary;

    &:focus {
        outline: 0.2rem solid $primary;
        outline-offset: 0;
    }
}

h2.section-title {
    margin: 0 auto $spacer;
    text-align: center;
    @include breakpoint($xsmall) {
        text-align: left;
    }
}


ul {
    padding-inline-start: 40px;
    margin: $spacer 0;

    li {
        margin-bottom: $spacer;
    
        &::marker {
            color: $primary;
        }
    
    }

}

// Font Awesome Free
$fa-font-path: "../fonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/brands";

.fa-solid::before {
    font-family: "Font Awesome 6 Free";
}