/* Section: About */

section.about {

    h4 {
        color: $gray-dark;
        font-weight: 600;
        text-align: center;

        @include breakpoint($tablet) {
            text-align: left;
        }
    }

    ul {
        font-size: initial;
    }

    .summary {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-bottom: 4rem;

        @include breakpoint($tablet) {
            flex-direction: row;
            margin-bottom: 0 !important;
        }

        .section-body {
            text-align: center;
            
            @include breakpoint($xsmall) {
                text-align: left;              
            }

        }

        .col {
            display: block;
            flex-grow: 1;
        }

    }

    .classification-boxcar,
    .classification-highcub {
        display: flex;
        flex-direction: column;
    }

    .classification-highcub {
        z-index: 1;
        max-width: 1200px;
        @include breakpoint($tablet) {
            margin-top: 2rem;
        }
        @include breakpoint($desktop-wide) {
            margin-top: -5rem;
        }

        @include breakpoint($desktop-xxl) {
            margin-top: -15rem;
        }

    }

    .classification-boxcar {

        h4 {
            align-self: center;
            margin: 0;
            padding: $spacer ($spacer * 2);
        }
    }

    .train {
        width: 100%;
        padding: 0 2rem;
        display: none;

        @include breakpoint($xsmall) {
            display: block;
        }

        @include breakpoint($tablet) {
            width: 40vw;
        }
    }

    .benefits {
        text-align: center;
        margin: 0;
        padding: 0;

        li {
            border-top: 1px dotted $primary-dull;
            padding-top: 1rem;
            display: block;

            @include breakpoint($xsmall) {
                border: 0;
                padding: 0;
                list-style-type: square;
                display: list-item;
            }
        }

        @include breakpoint($xsmall) {
            text-align: left;
            column-count: 2;
            list-style-type: square;
            padding-left: 1rem;
        }

    }

    .compare {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        // flex-direction: row;

        @include breakpoint($phone) {
            flex-direction: row;
            justify-content: space-evenly;
        }

        @include breakpoint($tablet) {
            justify-content: space-between;
        }
        
        ul {
            list-style-type: none;
            text-align: center;
            padding: 0;

            @include breakpoint($phone) {
                text-align: left;
            }

            &:last-child {
                display: none;

                @include breakpoint($tablet) {
                    display: block;
                }

            }

            li {   
                &::before {
                    font-family: "Font Awesome 6 Free";
                    color: $secondary;
                    margin: 0 .5rem;
                }              
            }
            
            li:first-child {
                list-style-type: none;
                font-weight: bold;
                font-family: $font-oswald;
                text-transform: uppercase;
                color: $primary;
            }

            li:nth-child(2) {
                &::before {
                    content: '\f482';
                }
            }

            li:nth-child(3) {
                &::before {
                    content: '\f4df';
                }
            }            

        }
    }
}