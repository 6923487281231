/* Main */

main {
    position: relative;
    z-index: 0;

    .row {
        display: flex;
        flex-wrap: wrap;

        .col {
            flex: 1 1 auto;
        }
    }

    &::before {
        @include breakpoint($tablet) {
            display: block;
            content: '';
            position: fixed;
            background-color: $gray-dark;
            width: 100%;
            height: 100px;
            opacity: 0.9;
            z-index: 2;
        }
    }
}