/* Section: Carbon Tracker */

section.carbon-footprint {
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    background: url(../images/carbon-bg.jpg) 50% no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 8rem 0 !important;

    h3 {
        color: $white;
    }

    .row {
        display: block;

        @include breakpoint($tablet) {
            display: inline-flex;
            flex-direction: column;
        }

        .co2 {
            &--number h1 {
                font-size: calc(5.5rem + 1vw);
                margin-bottom: $spacer;
                text-align: center;
                color: $white;
                font-weight: 900;
                @extend %lato-bold;

                @include breakpoint($tablet) {
                    font-size: calc(5.5rem + 1vw);
                }
            }

            // &--description {
            //     font-size: calc(1.88rem + 0.9vw);
            //     margin-bottom: $spacer * 0.75;
            // }

            &--cta {
                display: flex;
                // margin: $spacer;
                justify-content: center;
                margin: 2rem auto 0;
            }
        }
    }
}