.shipments {
    display: flex;
    flex-direction: column;

    @include breakpoint($tablet) {
        flex-direction: row;
    }

    .shipment-item {
        display: flex;
        flex-direction: column;
        flex: 1 1 30%;
        text-align: center;
        background-image: url(../images/WIS-BG2.png);
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: -1.5rem;

        @include breakpoint($tablet) {
            margin: 0;
        }

        .col {
            &:first-child {
                z-index: 1;
            }
        }

        &--name {
            color: $primary;
            font-weight: bolder;
            margin: .5rem;
            font-size: calc(1rem + 0.4vw);
        }

        &--location {
            margin: 0;
            
            .fa-solid {
                font-size: 75%;
                color: $secondary;
                margin: 0 0.5em;
            }

            .fa-location-dot {
                color: $secondary;
            }
        }

        &--customer {
            margin: 0;
        }

        &--image {
            width: 100%;
            filter: drop-shadow(0 3px 2px rgba(60, 60, 60, .5))
        }
    }
}
