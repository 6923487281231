/* Section: Hero */

section.hero {
    position: relative;
    background-color: $background;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    background: $black;
    z-index: 2;
    overflow: hidden;


    @include breakpoint($tablet) {
        height: 75vh;
    }

    .contents {
        width: 100%;
        margin: 8rem 0 1rem;

        .section {
            &-title {
                color: $white;
            }

            &-body {
                margin-bottom: $spacer * 2;
            }
        }
    }

    .background {
        display: none;

        @include breakpoint($tablet) {
            display: block;
            background: url('../images/background.jpg') no-repeat center center fixed;
            background-size: cover;
            height: 100%;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            filter: brightness(66%);
            top: 0;
            left: 0;
            z-index: -1;
            background-attachment: scroll;
            background-attachment: fixed;
            background-position: 33% top;
            background-repeat: no-repeat;
        }
    }

    @include breakpoint($tablet) {
        .contents {
            width: 60%;
        }
    }

    @include breakpoint($desktop) {
        .contents {
            width: 40%;
        }
    }
}