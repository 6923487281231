/* Footer */

footer {
    position: relative;
    background-color: $background;
    padding: $spacer;
    text-align: center;

    p {
        font-size: 1rem;
        margin-bottom: 0;
    }
}