/* Header */

header {
    z-index: 3;
    position: absolute;
    background-color: $background;
    overflow: hidden;
    
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        transition: all 0.7s;
        background-color: $black;
        padding-top: ($spacer * 1.3);
        padding-bottom: ($spacer * 1.3);

        @include breakpoint($tablet) {
            padding-left: 2%;
            padding-right: 2%;
        }

        @include breakpoint($desktop-small) {
            padding-left: 4%;
            padding-right: 4%;
        }

        @include breakpoint($desktop) {
            background-color: transparent;
            padding-top: ($spacer * 1.5);
            padding-bottom: ($spacer * 1.5);
        }

        .logo-container {
            display: flex;
            position: relative;
            vertical-align: middle; 

            .logo-content {
                display: none;

                @include breakpoint($desktop-small) {
                    max-width: 260px;
                    width: 100%;
                    display: block;             
                }


                &--mobile {
                    max-width: 120px;
                    width: 100%;
                    display: block;

                    .fill{
                        fill:#FFFFFF;
                    }

                    @include breakpoint($desktop-small) {
                        display: none;
                    }
                }
            }
        }
        
        .nav-logo {
            margin-right: auto;
            display: flex;
            text-decoration: none;
            align-items: center;
            z-index: 100;
            padding: 1rem 0;
            
            &--image {
                display: none;
                
                @include breakpoint($desktop-small) {
                    max-width: 240px;
                    width: 100%;
                    display: block;
                }

                @include breakpoint($desktop) {
                    max-width: 260px;
                    width: 100%;
                    display: block;
                }
            }

        }
        
        .main-nav {
            
            &.responsive {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                background-color: $black;
                padding: 100px 1rem 1rem;

                .navbar {
                    background-color: $black;
                    padding: 0;
                    margin: 0;
                    
                    .nav-item--link,
                    .nav-item--menu {
                        float: none;
                        display: block;
                        padding: $spacer;

                        @include breakpoint($tablet) {
                            padding: ($spacer * 1.2) ($spacer * 1.4);
                        }

                        @include breakpoint($desktop) {
                            padding: $spacer;
                        }
                    }
                    
                    .nav-submenu {
                        display: block;
                    }

                    .nav-item--icon {
                        position: absolute;
                        top: -60px;
                        right: 1%;
                        padding: 0.2rem 1rem;
                    }

                    .fa-bars::before {
                        content: "\f00d";
                    }

                }
            }
            
            
            .nav-item {
                margin: 0;
                position: relative;

                &--menu {
                    display: none;
                    color: $white;
                    font-size: calc(0.7rem + 0.45vw);
                    font-family: $font-lato !important;

                    @include breakpoint($tablet) {
                        padding: 0.5rem 0.5rem !important;
                        margin-left: $spacer * 0.5;
                        display: inline;
                        padding: $spacer;
                        background-color: $primary;
                        text-transform: uppercase;
                        color: $white;
    
                        &:after {
                            content: '\f078';
                            font-family: "Font Awesome 6 Free";
                            font-weight: 900;
                            padding-left: $spacer * 0.5;
                        }
                    }     
                    
                    @include breakpoint($desktop-small) {
                        margin-left: $spacer * 0.5;
                        padding: 0.5rem 0.8rem;
                    }      

                    @include breakpoint($desktop) {
                        @include btn-primary;
                        font-size: calc(0.5rem + 0.45vw);
                    }
                }

                .nav-submenu {
                    display: none;

                    @include breakpoint($tablet) {
                        display: none;
                    }

                    &--item {
                        .nav-item--link {
                            padding: 0 ($spacer * 0.5) ($spacer * 0.5) $spacer;
                            display: inline-flex;
                        }
                    }
                }

                &:hover .nav-submenu,
                & .nav-submenu:hover {
                    display: block;

                    @include breakpoint($tablet) {
                        display: block;
                        position: absolute;
                        width: fit-content;
                        white-space: nowrap;
                        top: 10px;
                        right: 0;
                        z-index: 4;
                        padding: 20px 10px 5px 5px;
                        background-color: $primary;
                    }                        
                }
                
                @include breakpoint($tablet) {
                    display: inline;
                }
                
                &--link {
                    display: none;
                    color: $white;
                    text-decoration: none;
                    position: relative;
                    font-size: calc(0.7rem + 0.45vw);
                    padding-right: $spacer;
                    
                    &:hover {
                        color: $secondary;
                    }

                    &[href$=".pdf"]:after {
                        content: '\f1c1';
                        font-family: "Font Awesome 6 Free";
                        font-weight: 900;
                        right: -15px;
                        position: absolute;
                        top: 0;
                        color: #ffffff;
                    }
                    
                    @include breakpoint($tablet) {
                        text-decoration: none;
                        padding: 0 ($spacer * 0.3);
                        text-transform: uppercase;
                        float: none;
                        display: inline;
                    }     
                    
                    @include breakpoint($desktop-small) {
                        padding: 0 ($spacer * 0.4);
                    }  
                    
                    @include breakpoint($desktop) {
                        padding: 0 ($spacer * 0.6);
                    }  
                }
                
                &--icon {
                    color: $secondary;
                    font-size: calc(1.4rem + 0.9vw);
                    
                    @include breakpoint($tablet) {
                        display: none;
                    }
                }
            }
        }
    }
}