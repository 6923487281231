// Section: Map

section.map {
    .section-body {
        margin-bottom: $spacer * 2;
    }

    .tabs {
        display: flex;
        height: auto;
        flex-direction: column;
        box-shadow: 0px 3px 15px rgba(0,0,0,0.1);

        @include breakpoint($tablet) {
            flex-direction: row;
            margin-top: $spacer * 2;
        }

        .tab-header {
            background-color: $background;
            height: auto;
            width: 100%;

            @include breakpoint($tablet) {
                width: 30vw;
            }
    
            &--link {
                display: block;
                padding: $spacer;
                width: 100%;
                text-align: left;
                cursor: pointer;
                transition: all 0.3s;
                border-radius: 0;
                background-color: $white;
                color: $gray-dark;

                &:hover {
                    background-color: $background;
                    color: $gray-dark;
                }

                &.inactive {
                    padding: $spacer;
                    color:$gray-dark;
                    background-color: $gray;
                    outline:none;
                    border-bottom: solid 1px $gray-medium !important;

                    &:hover {
                        outline: none;
                        background-color: $background;
                    }
                }
            }
        }

        .tab-body {
            position: relative;
        
            &--content {
                float: left;
                width: 100%;
                height: auto;
                // padding: $spacer * 2;
                background-color: $white;

                @include breakpoint($tablet) {
                    width: 70vw;
                    // padding: $spacer * 3;
                }

                @include breakpoint($desktop) {
                    // padding: $spacer * 4;
                }
            }

            &--map {
                margin: $spacer;
                width: calc(100% - 2rem);
                font-size: calc(0.65rem + 0.4vw);

                @include breakpoint($desktop) {
                    position: absolute;
                    z-index: 1;
                    top: 1.8rem;
                    left: calc(2.65rem + 0.4vw);
                    max-width: 40%;
                    margin: 0;
                }
            }

            &--Atlanta {
                @include breakpoint($desktop) {
                    right: calc(2.65rem + 0.4vw);
                    left: auto;
                }
            }

            &--heading {
                color: $gray-dark;
            }

            &--image {
                width: 100%;
                // margin-bottom: $spacer;
            }

            .map-link {
                display: block;
                cursor: pointer;
                
                &::before {
                    content: "\f35d";
                    font-family: "Font Awesome 6 Free";
                    position: absolute;
                    top: 0.5rem;
                    right: 0.5rem;
                }

            }

            .zip-container {
                width: 100%;
                height: auto;
                clear: both;
                padding: 0 1rem 1rem;
                background-color: $white;

                button {
                    margin-bottom: 0;
                }
                
                @include breakpoint($desktop) {
                    width: 70vw;
                    padding: calc(2.65rem + 0.4vw);
                    // padding: $spacer * 3;
                }

                @include breakpoint($desktop) {
                    // padding: $spacer * 4;
                }
            }
        }
    }
}