// colors

$white: #FFFFFF;
$black: #000000;

$button-color: $white;
$button-text: $black;
$button-color-hover: $black;
$button-text-hover: $white;

$background: rgba(60, 100, 177, 6%);
$gray: #F7F7F7;
$gray-dark: #262626;
$gray-medium: rgba(60, 60, 60, 0.15);

$primary: #026982;
$primary-dull: #4EBCD3;
$secondary: #ffc354;
$secondary-light: rgba(255, 195, 84, 0.7);
$danger: #7a0000;

$spacer: 1rem;
