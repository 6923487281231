/* Page: Home */

html {
    scroll-behavior: smooth;
}

body.page-home {
    $page: 'home';
    background: $gray;
}

.container {
    width: 100%;
    padding-right: 4%;
    padding-left: 4%;
    margin-right: auto;
    margin-left: auto;

    @include breakpoint($tablet) {
        padding-left: 5%;
        padding-right: 5%;
    }

    @include breakpoint($desktop) {
        padding-left: 6%;
        padding-right: 6%;
    }
}